class VolcapAudio {
    constructor(audioContext, audioGainNode) {
        this._context = null;
        this._destinationNode = null;
        this._currentSource = null;
        this._nextSource = null;
        this._current = null;
        this._buffers = [];
        this._lastTime = 0;

        this._playing = false;

        this._context = audioContext;
        this._destinationNode = audioGainNode;
        if(!this._context || ! this._destinationNode) {
            throw new Error('VolcapAudio created without AudioContext or DesticationNode');
        }
    }

    add(startTime, input) {
        if(!input) {
            throw new Error('VolcapAudio source must be valid');
        } else if (input instanceof ArrayBuffer) {
            this._context.decodeAudioData(input, function(audioBuffer) {
                this._add(startTime, audioBuffer);
            }.bind(this), function(e) {
                console.log("Error decoding audio data: " + e.message);
            })
        } else if(typeof input === 'string' || input instanceof String) {
            fetch(input)
            .then(response => response.arrayBuffer())
            .then(dataBuffer => {
                this._context.decodeAudioData(dataBuffer, function(audioBuffer) {
                    this._add(startTime, audioBuffer);
                }.bind(this), function(e) {
                    console.log("Error decoding audio data: " + e.message);
                })
            });
        }
    }

    play(currentTime, duration) {
        if(!this._playing || (this._playing && this._lastTime > currentTime)) {
            this._playing = true;
            this._reset(currentTime);
            this.update(currentTime, duration);
        }
        this._lastTime = currentTime;
    }

    pause() {
        if(this._playing) {
            this._playing = false;
            this._reset(0.0);
        }
    }

    seek(time) {
        this._reset(time);
    }

    update(currentTime, duration) {
        if(!this._playing) {
            return;
        }

        let data = this._find(currentTime);
        if(data && data != this._current) {
            this._current = data;
            this._currentSource = this._nextSource;

            let source = this._source = this._context.createBufferSource();
            source.buffer = this._current.b;
            source.connect(this._destinationNode);
            let startTime = this._context.currentTime;
            let offset = 0.0;
            let difference = currentTime - this._current.s;
            if(difference < 0.0) {
                startTime += difference;
                if(startTime < 0) {
                    startTime = 0.0;
                }
            } else {
                offset = difference;
            }
            source.start(startTime, offset);
            // TODO: queue up next audio source to avoid popping
            //source.start(0);
            //if(!this._currentSource)
                this._currentSource = source;
            //else {
            //    this._nextSouce = source;
            //}
            return true;
        } 
        return false;
    }

    _reset(time) {
        if(this._currentSource) {
            this._currentSource.stop();
            this._currentSource = null;
        }
        if(this._nextSource) {
            this._nextSource.stop();
            this._nextSource = null;
        }
        this._current = null;
    }

    _add(startTime, buffer) {
        if(!buffer) {
            throw new Error('VolcapAudio failed to decode audio');
        }
        if(!this._find(startTime)) {
            this._buffers.push(
                {
                    s: startTime, 
                    e: startTime + buffer.duration,
                    b: buffer
                }
            );
        }
    }

    _find(time) {
        var next = -1;
        for (var idx in this._buffers) {
            if(time >= this._buffers[idx].s && time < this._buffers[idx].e) {
                return this._buffers[idx];
            } else if(time < this._buffers[idx].s && (next == -1 || this._buffers[idx].s < this._buffers[next].s)) {
                next = idx;
            }
        }
        if(next != -1)
            return this._buffers[next];
        return null;
    }
}

module.exports = VolcapAudio;