const MP4Box = require('mp4box');
const DRACOLoader = require('./draco_loader.js');

const script = document.currentScript;
const libraryPath = script.src.replace(/[^/]*$/, '');
console.log(libraryPath);

let loader = new DRACOLoader();
loader.setDecoderPath(libraryPath);

/**
 * A SourceBuffer implementation for Draco Mesh data.
 * 
 * @link https://developer.mozilla.org/en-US/docs/Web/API/MediaSource
 * @param {Object} canvas HTMLCanvas object
 * @param {Object} mimeType mimeType of the source mesh
 * @class MeshSourceBuffer
 */
class MeshSourceBuffer {
    constructor(mimeType) {
        // byte arrays queued to be appended
        this.buffer_ = [];

        // the total number of queued bytes
        this.bufferSize_ = 0;

        // to be able to determine the correct position to seek to, we
        // need to retain information about the mapping between the
        // media timeline and PTS values
        this.basePtsOffset_ = NaN;

        this.audioBufferEnd_ = NaN;
        this.videoBufferEnd_ = NaN;

        // indicates whether the asynchronous continuation of an operation
        // is still being processed
        // see https://w3c.github.io/media-source/#widl-SourceBuffer-updating
        this.updating = false;
        this.timestampOffset_ = 0;

        this.bytesReceived = 0;

        this.ranges = [];
        this.rawSamples = [];
        this.samples = [];

        this._key = null;

        this.mp4Parser = MP4Box.createFile();
        this.mp4Parser.onReady = (info) => {
            this.mp4Parser.setExtractionOptions(info.tracks[0].id, null, {nbSamples: 1});
            this.mp4Parser.onSamples = this.onSamples.bind(this);
            this.mp4Parser.start();
        }
        this.mp4Parser.onError = (e) => {
            console.error(e);
        }

        Object.defineProperty(this, 'timestampOffset', {
            get() {
                return this.timestampOffset_;
            },
            set(val) {
                if (typeof val === 'number') {
                    this.timestampOffset_ = val;
                }
            }
        });

        Object.defineProperty(this, 'buffered', {
            get() {
                const ranges = [...this.ranges];
                // The buffered read-only property of the SourceBuffer interface returns the time ranges that are currently buffered in the SourceBuffer as a normalized TimeRanges object.
                return {
                    length: ranges.length,
                    start: function(i) { return ranges[i].start; },
                    end: function(i) { return ranges[i].end; }
                };
            }
        });
    }

    setKey(key) {
        console.log('set decryption key');
        this._key = key;
        this.maybeProcess();
    }

    onSamples(id, user, [sample]) {
        this.rawSamples.push(sample);
    }

    calculateRanges() {
        let ranges = [];
        for (let sample of this.samples) {
            let start = sample.timestamp / this.timescale_ + this.timestampOffset_;
            let duration = this.frameDuration || sample.duration;
            let end = start + duration / this.timescale_ + this.timestampOffset_;
            if (ranges.length === 0) {
                // Add the first range entry
                ranges.push({start, end})
            } else {
                let ridx = ranges.length - 1
                // Either extend the current range or add a new one;
                if (start > ranges[ridx].end + this.frameDuration * 2) {
                    ranges.push({start, end})
                } else {
                    ranges[ridx].end = end;
                }
            }
        }
        this.ranges = ranges;
    }

    // Process accumulated raw samples if a decryption key is required and available
    maybeProcess() {
        if (this.rawSamples.length > 0 && (!this.rawSamples[0].encrypted || this._key)) {
            this.timescale_  = this.rawSamples[0].timescale;

            // Map each raw sample to a Promise that will resolve when it is finished
            // decoding
            let decodeSamples = this.rawSamples.map((sample) => this.decodeData(sample).then((samples) => this.samples.push(...samples)));
            Promise.all(decodeSamples)
                .then(() => {
                    if (!this.frameDuration) {
                        this.frameDuration = this.samples[1].timestamp - this.samples[0].timestamp;
                    }
                    this.calculateRanges();
                    this.rawSamples = [];
                    this.updating = false;
                });
        }
    }

    /**
     * Append bytes to the sourcebuffers buffer.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/appendBuffer
     * @param {Array} bytes
     */
    appendBuffer(bytes) {
        let error;

        if (this.updating) {
            error = new Error('SourceBuffer.append() cannot be called ' +
                'while an update is in progress');
            error.name = 'InvalidStateError';
            error.code = 11;
            throw error;
        }
        bytes.fileStart = this.bytesReceived;
        this.bytesReceived = this.mp4Parser.appendBuffer(bytes);
        if (this.rawSamples.length > 0) {
            this.updating = true;
            this.maybeProcess();
        }
    }

    getTiming() {
        return {duration: this.frameDuration, timescale: this.timescale_};
    }

    /**
     * Reset the parser and remove any data queued to be sent decoder.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/abort
     */
    abort() {
        this.buffer_ = [];
        this.bufferSize_ = 0;

        // report any outstanding updates have ended
        if (this.updating) {
            this.updating = false;
        }
    }

    /**
     * Remove mesh within the given time range.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/remove
     * @param {Double} start start of the section to remove
     * @param {Double} end end of the section to remove
     */
    remove(start, end) {
        // If start time falls in this range
        const tend = end * this.timescale_;
        const tstart = start * this.timescale_;
        this.samples = this.samples.filter((sample) => sample.timestamp > tend || sample.timestamp < tstart);
        this.calculateRanges();
    }

    /* Decode a single DRACO sample, possibly yielding multiple geometries.
     */
    async decodeData(sample) {
        // The following is mostly useful for live mode
        const sampleTimestamp = sample.dts;
        let taskConfig = {
            attributeIDs: loader.defaultAttributeIDs,
            attributeTypes: loader.defaultAttributeTypes,
            metadataFields: {
                version: 'StringEntry',
                timestamp: 'IntEntryArray',
                interpolatedFrames: 'IntEntryArray',
                deltaFrames: 'IntEntryArray',
                deltaIds: 'IntEntryArray',
                frameId: 'IntEntry',
                weightsAtt: 'IntEntry',
                bindingsAtt: 'IntEntry',
                headJoint: 'EntryDoubleArray',
                headJointIndex: 'IntEntry',
                neckJoint: 'EntryDoubleArray',
                neckJointIndex: 'IntEntry',
            },
            useUniqueIDs: false,
            timestamp: sampleTimestamp,
        };
        let data = sample.data;
        if (sample.encrypted) {
            try {
                // IV must be extracted from sample senc box and padded out to 16 bytes if less.
                const iv = new Uint8Array(16);
                iv.set(sample.InitializationVector);
                let ab = await window.crypto.subtle.decrypt(
                    {
                        name: "AES-CTR",
                        counter: iv,
                        length: 128,
                    },
                    this._key,
                    data
                );
                data = new Uint8Array(ab);
            } catch (e) {
                console.error('mesh decryption error', e);
            }
        }
        const geometry = await loader.decodeGeometry(data.buffer, taskConfig);
        const metadata = Object.assign({deltaFrames: [], interpolatedFrames: []}, geometry.metadata);
        const deltaIds = metadata.deltaIds;

        let sampleCount = metadata.timestamp ? metadata.timestamp.length : 1;
        let samples = new Array(sampleCount);
        const duration = metadata.timestamp ? sample.duration / metadata.timestamp.length : sample.duration;
        let weightsAtt, bindingsAtt;
        for ( let i = 0; i < sampleCount; i++) {
            let frameId = metadata.frameId !== undefined ? metadata.frameId : null;
            const timestamp = metadata.timestamp !== undefined ? metadata.timestamp[i] : sampleTimestamp;
            const {headJointIndex, neckJointIndex, headJoint, neckJoint} = metadata;
            let userData = {};
            if (headJointIndex && headJoint) {
                Object.assign(userData, {headJointIndex, headJoint: new THREE.Vector3(headJoint[i*3], headJoint[i*3+1], headJoint[i*3+2])})
            }
            if (neckJointIndex && neckJoint) {
                Object.assign(userData, {neckJointIndex, neckJoint: new THREE.Vector3(neckJoint[i*3], neckJoint[i*3+1], neckJoint[i*3+2])})
            }
            if (i == 0) {
                geometry.userData = userData;
                const position = geometry.attributes.position;
                const itemCount = 4;
                const size = position.count * itemCount;
                if (geometry.attributes.weightsAtt) {
                    weightsAtt = geometry.attributes.weightsAtt.clone();
                } else {
                    weightsAtt = new THREE.BufferAttribute(new Float32Array(size), itemCount);
                    geometry.setAttribute('weightsAtt', weightsAtt);
                }
                if (geometry.attributes.bindingsAtt) {
                    // we have to conver the Int32Array to a Float32Array due to GLSL attribute type limitations on iOS
                    bindingsAtt = new THREE.BufferAttribute(Float32Array.from(geometry.attributes.bindingsAtt.array), itemCount);
                    geometry.setAttribute('bindingsAtt', bindingsAtt);
                } else {
                    bindingsAtt = new THREE.BufferAttribute(new Float32Array(size), itemCount);
                    geometry.setAttribute('bindingsAtt', bindingsAtt);
                }
                samples[i] = {type: 'full', geometry, timestamp, frameId, duration};
            } else {
                let geo = new THREE.BufferGeometry();
                geo.setIndex(geometry.index);
                geo.setAttribute('uv', geometry.attributes.uv.clone());
                geo.setAttribute('position', geometry.attributes.position.clone());
                geo.setAttribute('weightsAtt', weightsAtt);
                geo.setAttribute('bindingsAtt', bindingsAtt);
                geo.userData = userData;
                samples[i] = {geometry: geo, timestamp, frameId, duration};
            }
        }

        metadata.deltaFrames.forEach(id => samples[id].type = 'delta');
        metadata.interpolatedFrames.forEach(id => samples[id].type = 'interpolated');

        // Loop once to populate delta positions
        let deltaIdx = 0;
        let prevDeltas;
        for (let idx = 0; idx < samples.length; idx++) {
            if (samples[idx].type === 'delta') {
                let deltaPositions = geometry.attributes[deltaIds[deltaIdx++]];
                if (prevDeltas) {
                    let {count, itemSize} = samples[idx].geometry.attributes.position;
                    for (let i = 0; i < count * itemSize ; i++) {
                        deltaPositions.array[i] += prevDeltas.array[i];
                    }
                }
                // store deltas as an attribute on this frame for later lookup
                samples[idx].geometry.setAttribute('delta', deltaPositions);
                // Apply deltas to original position
                let {count, itemSize} = samples[idx].geometry.attributes.position;
                for (let i = 0; i < count * itemSize ; i++) {
                    samples[idx].geometry.attributes.position.array[i] += deltaPositions.array[i];
                }
                samples[idx].geometry.attributes.position.needsUpdate = true;
                prevDeltas = deltaPositions;
            }
        }

        // Loop again to interpolate in between
        for (let idx = 0; idx < samples.length; idx++) {
            if (samples[idx].type === 'interpolated') {
                let forwardIdx, backwardIdx;
                for (let i = idx; i < samples.length; i++) {
                    if (samples[i].type === 'delta') {
                        forwardIdx = i;
                        break;
                    }
                }
                for (let i = idx; i >= 0; i--) {
                    if (samples[i].type === 'delta' || samples[i].type === 'full') {
                        backwardIdx = i;
                        break;
                    }
                }
                const currentDistance = idx - backwardIdx;
                const totalDistance = forwardIdx - backwardIdx;
                const interp = currentDistance / totalDistance;
                if (samples[backwardIdx].type === 'full') {
                    const backwardPositions = samples[backwardIdx].geometry.attributes.position;
                    const forwardDeltas = samples[forwardIdx].geometry.attributes.delta;
                    let {count, itemSize} = backwardPositions;
                    for (let i = 0; i < count * itemSize ; i++) {
                        samples[idx].geometry.attributes.position.array[i] =
                            backwardPositions.array[i] + interp * forwardDeltas.array[i];
                    }
                }
                else {
                    const firstPositions = samples[0].geometry.attributes.position;
                    const backwardDeltas = samples[backwardIdx].geometry.attributes.delta;
                    const forwardDeltas = samples[forwardIdx].geometry.attributes.delta;
                    let {count, itemSize} = backwardDeltas;
                    for (let i = 0; i < count * itemSize ; i++) {
                        samples[idx].geometry.attributes.position.array[i] =
                                firstPositions.array[i] + backwardDeltas.array[i] + interp * (forwardDeltas.array[i] - backwardDeltas.array[i]);
                    }
                }
                samples[idx].geometry.attributes.position.needsUpdate = true;
            }
        }

        // Loop again to scale them all
        for (let idx = 0; idx < samples.length; idx++) {
            samples[idx].geometry.scale(0.01, 0.01, 0.01);
        }

        return samples;
    }

    getMesh(time) {
        let sample = this.samples.find((sample) => sample.timestamp == time);
        if (sample) {
            return sample.geometry;
        }
    }

    getMeshByFrameId(frameId) {
        let sample = this.samples.find((sample) => sample.frameId == frameId);
        if (sample) {
            return sample.geometry;
        }
    }
}

let initialized = false;
let isFallback = false;

let sourceAddedCb;
let meshSourceBuffers = [];

function meshPolyfill() {
    // Use a polyfill on the video's MediaSource API to intercept calls to addSourceBuffer
    const addSourceBuffer = window.MediaSource.prototype.addSourceBuffer;
    window.MediaSource.prototype.addSourceBuffer = function(...varArgs) {
        let mimeType = varArgs[0];
        if (mimeType === "mesh/fb;codecs=\"draco.514\"" || mimeType === "mesh/mp4;codecs=\"draco.514\"") {
            let meshSourceBuffer = new MeshSourceBuffer(varArgs);
            if (typeof sourceAddedCb === 'function') sourceAddedCb(meshSourceBuffer);
            meshSourceBuffers.push(meshSourceBuffer);
            return meshSourceBuffer;
        } else {
            return addSourceBuffer.apply(this, varArgs);
        }
    }

    const removeSourceBuffer = window.MediaSource.prototype.removeSourceBuffer;
    window.MediaSource.prototype.removeSourceBuffer = function(...varArgs) {
        let buffer = varArgs[0];
        if (buffer instanceof MeshSourceBuffer) {
            meshSourceBuffers = meshSourceBuffers.filter((b) => b != buffer);
        } else {
            return removeSourceBuffer.apply(this, varArgs);
        }
    }

    const isTypeSupported = window.MediaSource.isTypeSupported;
    window.MediaSource.isTypeSupported = function(codec) {
        if (codec === "mesh/fb;codecs=\"draco.514\"") {
            return true;
        } else if (codec === "mesh/mp4;codecs=\"draco.514\"") {
            return true;
        } else {
            return isTypeSupported(codec);
        }
    }
}

function destroy() {
    sourceAddedCb = undefined;
}

function initialize(src, cb, sourceAdded) {
    sourceAddedCb = sourceAdded;
    if (initialized) {
        return cb(isFallback);
    }

    let requestMediaKeySystemAccessPolyfill = (system, configs) => {
        return Promise.resolve({
            createMediaKeys: function() {
                return Promise.resolve({
                    createSession: function(type) {
                        let aHandler;
                        return {
                            sessionId: '1',
                            addEventListener: function(name, handler) {
                                aHandler = handler;
                            },
                            closed: new Promise((resolve, reject) => {

                            }),
                            generateRequest: function(dataType, initData) {
                                // must spoof a 'message' event to the handler with
                                let td = new TextDecoder()
                                let str_body = td.decode(initData);
                                let json_body = JSON.parse(str_body);
                                let msg_json = Object.assign(json_body, {type: 'temporary'});
                                let msg_str = JSON.stringify(msg_json);
                                let te = new TextEncoder();
                                let message = te.encode(msg_str).buffer;
                                let me = new MediaKeyMessageEvent('message', {
                                    messageType: 'license-request',
                                    message,
                                })
                                aHandler.handleEvent(me);
                                return Promise.resolve();
                            },
                            update: function(message) {
                                return Promise.resolve();
                            }
                        };
                    }
                });
            }
        });
    };

    if (window.MediaSource === undefined) {
        isFallback = true;
        // we're likely on an iPhone, where MSE is not supported. now we need to
        // trick dash.js into initializing correctly. we extend Blob so that
        // URL.createObjectURL() can still succeed with this polyfill, although it
        // isn't actually going to be used.
        fetch(src, {
            headers: {
                'Range': 'bytes=0-2686976'
            }
        })
        .then((image) => {
            if (image.ok) {
                return image.blob();
            } else {
                throw new Error('Failed to prime source');
            }
        })
        .then((blob) => {
            window.MediaSource = class IOSMediaSource extends Blob {
                constructor() {
                    super([blob], {type: 'video/mp4'});
                    Object.setPrototypeOf(this, IOSMediaSource.prototype);
                    this._state = 'open';
                }

                get readyState () {
                    return this._state;
                }

                get duration () {
                    return this._duration;
                }

                set duration (duration) {
                    this._duration = duration;
                }

                get sourceBuffers () {
                    return new Array();
                }
        
                addEventListener(event, cb) {
                    switch (event) {
                    case 'sourceopen':
                        cb();
                        break;
                    default:
                        break;
                    }
                }

                removeEventListener(event) {
                }
        
                addSourceBuffer(varArgs) {
                }
        
                static isTypeSupported(codec) {
                    return false;
                }

                endOfStream(err) {
                }
            }
            meshPolyfill();
            // This API doesn't work on iOS - the Promise never
            // resolves or rejects - and this prevents the ClearKey CDM from
            // initializing.
            window.navigator.requestMediaKeySystemAccess = requestMediaKeySystemAccessPolyfill;
            initialized = true;
            cb(isFallback);
        })
        .catch((e) => console.error(e.message));
    } else {
        meshPolyfill();
        initialized = true;
        cb(isFallback);
    }
}

module.exports = {
    initialize,
    destroy
};
