
const helpers = require('./helpers');
require('./aframe-helpers');
const VolcapActor = require('./actor');
const VolcapDashActor = require('./actor/dash');

// This is our public API surface
class EightiHologram {
    constructor(src, scene, renderer, camera, opts = {}) {
        // Destructure the opts to set defaults.
        // TODO: Source these from one place and validate 'type' like A-Frame
        let defaults = {
            abr: true,
            autoplay: true,
            loop: false,
            muted: true,
            fps: -1,
        };
        let defaultedOpts = {};
        Object.assign(defaultedOpts, defaults, opts);
        this._impl = new VolcapDashActor(src, renderer, scene, camera, defaultedOpts);

        this._impl.onplay = (evt) => {
            if (typeof this.onplay === 'function') this.onplay(evt);
        }
        this._impl.onpause = (evt) => {
            if (typeof this.onpause === 'function') this.onpause(evt);
        }
        this._impl.onended = (evt) => {
            if (typeof this.onended === 'function') this.onended(evt);
        }
        this._impl.oncanplay = (evt = {}) => {
            if (typeof this.oncanplay === 'function') this.oncanplay(evt);
        }
        this._impl.onbufferempty = (evt = {}) => {
            if (typeof this.onbufferempty === 'function') this.onbufferempty(evt);
        }
        this._impl.onbufferloaded = (evt = {}) => {
            if (typeof this.onbufferloaded === 'function') this.onbufferloaded(evt);
        }
    }

    play() {
        this._impl.play()
    }

    pause() {
        this._impl.pause()
    }

    get paused() {
        return this._impl.paused;
    }

    get muted() {
        return this._impl.muted;
    }

    set muted(mute) {
        this._impl.muted = mute;
    }

    get duration() {
        return this._impl.duration;
    }

    get currentTime() {
        return this._impl.currentTime;
    }

    set currentTime(time) {
        this._impl.currentTime = time;
    }

    get mesh() {
        return this._impl._mesh;
    }

    get loop() {
        return this._impl.loop;
    }

    set loop(loop) {
        this._impl.loop = loop;
    }

    update(time) {
        this._impl.update(time);
    }

    destroy() {
        this._impl.destroy();
    }
}

if (window.AFRAME) {
    window.AFRAME.registerComponent('hologram', {
        schema: {
            abr: {type: 'boolean', default: true},
            'initial-quality-ratio': {type: 'number', default: 0},
            src: {type: 'string', default: ""},
            autoplay: {type: 'boolean', default: true},
            loop: {type: 'boolean', default: false},
            muted: {type: 'boolean', default: true},
            fps: {type: 'number', default: -1},
            'head-tracking': {type: 'boolean', default: false},
            'touch-target-size': {default: '1.65 0.35'},
            'touch-target-offset': {default: '0 0'},
            'touch-target-visible': {default: false},
        },
        init: function () {
            let data = this.data;
            let el = this.el;
            let src = data.src;
            // let scene = this.el.sceneEl.object3D;  // THREE.Scene
            let camera = this.el.sceneEl.camera;
            let renderer = this.el.sceneEl.renderer;
            this._impl = new EightiHologram(src, null, renderer, camera, data);
            this._impl.onplay = (evt) => {
                this.el.emit('onplay', evt);
            }
            this._impl.onpause = (evt) => {
                this.el.emit('onpause', evt);
            }
            this._impl.onended = (evt) => {
                this.el.emit('onended', evt);
            }
            this._impl.onbufferempty = (evt = {}) => {
                this.el.emit('onbufferempty', evt);
            }
            this._impl.onbufferloaded = (evt = {}) => {
                this.el.emit('onbufferloaded', evt);
            }
            this._impl.oncanplay = (evt = {}) => {
                this.el.emit('oncanplay', evt);
            }
            el.setObject3D('mesh', this._impl.mesh);

            // Make a fancy cylinder to encompass the hologram mesh.  This will
            // make it easier for the 8thWall touch raycasting, etc.
            const [height, width] = data['touch-target-size'].split(' ').map(v => Number(v));
            const [x, z] = data['touch-target-offset'].split(' ').map(v => Number(v));
            const debug = data['touch-target-visible'];
            const geometry = new THREE.CylinderGeometry( width, width, height, 32 );
            const material = new THREE.MeshBasicMaterial( {color: 0xffff00, transparent: true, opacity: debug ? 0.1 : 0} );
            material.depthTest = false;
            const cylinder = new THREE.Mesh( geometry, material );
            geometry.translate( x, height / 2, z );
            el.setObject3D('cylinder', cylinder);

            this._ready = false;

            Object.defineProperty(this, 'paused', {
                get() {
                    return this._impl.paused;
                }
            });

            // Override default 'pause' handler from A-Frame to prevent
            // tick/tock interruption.
            this.pause = () => {
                this._impl.pause();
            }

            Object.defineProperty(this, 'currentTime', {
                get() {
                    return this._impl.currentTime;
                },
                set(time) {
                    this._impl.currentTime = time;
                }
            });

            Object.defineProperty(this, 'duration', {
                get() {
                    return this._impl.duration;
                }
            });

            Object.defineProperty(this, 'muted', {
                get() {
                    return this._impl.muted;
                },
                set(muted) {
                    this._impl.muted = muted;
                }
            });

            Object.defineProperty(this, 'loop', {
                get() {
                    return this._impl.loop;
                },
                set(loop) {
                    this._impl.loop = loop;
                }
            });

            // This is normally provided by A-Frame itself, but we need to
            // override it to represent the actual video playback state so we
            // can use the play() method.
            Object.defineProperty(this, 'isPlaying', {
                get() {
                    return !this._impl.paused;
                }
            });
        },
        tick: function (time, timeDelta) {
            if (this._impl) {
                this._impl.update(time);
            }
            // This is CRITICAL for WebXR mode - we need to re-bind the WebXR
            // framebuffer or nothing will be rendered to the XR display.
            const gl = this.el.sceneEl.renderer.getContext();
            if (this.el.sceneEl.frame) {
                const frame = this.el.sceneEl.frame;
                let origFB = frame ? frame.session.renderState.baseLayer.framebuffer : null;
                gl.bindFramebuffer(gl.FRAMEBUFFER, origFB);
            }
        },
        update: function (prev) {
            let {data, el} = this;

            if (prev.src && prev.src != data.src) {
                console.error('Changing source not supported. Please destroy and re-create hologram');
            }

            if (prev.fps && prev.fps != data.fps) {
                console.error('Changing fps not supported. Please destroy and re-create hologram');
            }

            this._impl.muted = data.muted;
            this._impl.autoplay = data.autoplay;
            this._impl.loop = data.loop;
        },
        remove: function () {
            this._impl.destroy();
            this._impl = null;
            this.el.removeObject3D('mesh');
        },
        seek: function (time) {
            if (this._impl.seek) {
                this._impl.seek(time)
            } else {
                console.error('Seek not supported.');
            }
        },
        pause: function () {},
        play: function () {
            // skip the first 'play', which occurs after the
            // entity is added to the scene. The 'autoplay'
            // property should be used to control playback start
            if (this._ready) {
                this._impl.play();
            }
            this._ready = true;
        }
    });
    window.AFRAME.registerPrimitive('eighti-hologram', {
        defaultComponents: {
          'hologram': {},
        },
        mappings: {
            abr: 'hologram.abr',
            'initial-quality-ratio': 'hologram.initial-quality-ratio',
            src: 'hologram.src',
            autoplay: 'hologram.autoplay',
            loop: 'hologram.loop',
            muted: 'hologram.muted',
            fps: 'hologram.fps',
            'head-tracking': 'hologram.head-tracking',
            'touch-target-visible': 'hologram.touch-target-visible',
            'touch-target-size': 'hologram.touch-target-size',
            'touch-target-offset': 'hologram.touch-target-offset'
        }
      });
}

module.exports = EightiHologram;