if (window.AFRAME) {
    console.log('register A-Frame helpers');
    AFRAME.registerComponent('eighti-ar-hide', {
        init: function () {
            this.el.sceneEl.addEventListener('enter-vr', (ev) => {
                if (this.el.sceneEl.is('ar-mode')) {
                    this.el.setAttribute('visible', false);
                }
            });
            this.el.sceneEl.addEventListener('exit-vr', (ev) => {
                this.el.setAttribute('visible', true);
            });
        }
    });

    AFRAME.registerComponent('eighti-ar-hit-test', {
        schema: {
            target: { type: 'string', default: "" },
        },
        init: function () {
            this.xrHitTestSource = null;
            this.viewerSpace = null;
            this.refSpace = null;

            this.el.sceneEl.renderer.xr.addEventListener('sessionend', (ev) => {
                this.viewerSpace = null;
                this.refSpace = null;
                this.xrHitTestSource = null;
                let position = this.initialPosition || '0 0 0';
                this.target.setAttribute('position', position);
                this.el.setAttribute('visible', false);
            });
            this.el.sceneEl.renderer.xr.addEventListener('sessionstart', (ev) => {
                let session = this.el.sceneEl.renderer.xr.getSession();
                let data = this.data;
                this.target = document.querySelector(data.target);
                this.initialPosition = this.target.getAttribute('position');

                let element = this.el;
                session.addEventListener('select', () => {
                    let position = element.getAttribute('position');
                    if (this.target) {
                        this.target.setAttribute('position', position);
                        this.target.setAttribute('visible', true);
                    }
                });

                session.requestReferenceSpace('viewer').then((space) => {
                    this.viewerSpace = space;
                    session.requestHitTestSource({ space: this.viewerSpace })
                        .then((hitTestSource) => {
                            this.xrHitTestSource = hitTestSource;
                        })
                        .catch((e) => {
                            console.error('no hit-test support');
                            this.target.setAttribute('visible', true);
                        });
                });
                session.requestReferenceSpace('local').then((space) => {
                    this.refSpace = space;
                });
            });
        },
        tick: function () {
            if (this.el.sceneEl.is('ar-mode')) {
                if (!this.viewerSpace) return;

                let frame = this.el.sceneEl.frame;
                let xrViewerPose = frame.getViewerPose(this.refSpace);

                if (this.xrHitTestSource && xrViewerPose) {
                    let hitTestResults = frame.getHitTestResults(this.xrHitTestSource);
                    if (hitTestResults.length > 0) {
                        this.el.setAttribute('visible', true);
                        let pose = hitTestResults[0].getPose(this.refSpace);

                        let inputMat = new THREE.Matrix4();
                        inputMat.fromArray(pose.transform.matrix);

                        let position = new THREE.Vector3();
                        position.setFromMatrixPosition(inputMat);
                        this.el.setAttribute('position', position);
                        return;
                    }
                }
                this.el.setAttribute('visible', false);
            }
        }
    });

    AFRAME.registerComponent('eighti-controls-live', {
        init() {
            const holo = this.el.components.hologram
            document.head.insertAdjacentHTML('beforeend',
                `<style type="text/css">
                #content-container {
                    height: 100%;
                }
                .over {
                    z-index: 10;
                    pointer-events: none;
                    position: absolute;
                    top: 0; left: 0; bottom: 0; right: 0;
                    text-align: center;
                    font-family: 'Nunito', sans-serif;
                }
        
                #promptText {
                    width: 100vw;
                    position: absolute;
                    bottom: 10%;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
        
                svg {
                    font-size: 7vw;
                    letter-spacing: 1px;
                    font-weight: bold;
                    fill: white;
                }
        
                .svgStroke {
                    fill: white;
                    stroke: black;
                    stroke-width: 5px;
                    stroke-linejoin: round;
                }
                @font-face {
                    font-family: 'Simple-Line-Icons';
                    src:url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.eot');
                    src:url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.eot?#iefix') format('embedded-opentype'),
                      url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.woff') format('woff'),
                      url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.ttf') format('truetype'),
                      url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
                    font-weight: normal;
                    font-style: normal;
                }
                .controls {
                    position: absolute;
                    bottom: 0px;
                    z-index: 5;
                }
                .icon {
                    font-family: 'Simple-Line-Icons';
                    color: white;
                    speak: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    font-weight: bold;
                    font-size: 30px;
                    padding: 10px;
                    display: inline-block;
                    -webkit-user-select: none; /* Chrome/Safari */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+ */
                    -o-user-select: none;
                    user-select: none;
                    cursor: pointer;
                    vertical-align: bottom;
                    pointer-events: auto;
                }
                .volume-unmuted:before {
                    content: "\\e0a0";
                }
                .volume-muted:before {
                    content: "\\e0a1";
                }
            </style>`);
            this.el.insertAdjacentHTML('beforeend',
                `<!-- UI -->
      <div class="over">
        <span id="promptText">
          <svg width="100%">
            <text class="svgStroke" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">Loading...</text>
            <text class="svgText" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">Loading...</text>
          </svg>
        </span>
        <div class="controls">
            <div id="muteBtn" class="icon volume-unmuted" style="display:none">
        </div>
      </div>`)
            const prompt = document.getElementById('promptText').getElementsByTagName('text')
            const muteBtn = document.getElementById('muteBtn');

            // You can use event handlers on the eighti-hologram element to monitor playback progress:
            // 'onplay'
            // 'onpause'
            // 'onended'
            // 'oncanplay' - the hologram has loaded and you can issue a play() call to the component

            this.el.addEventListener('oncanplay', (evt) => {
                for (let i = 0; i < prompt.length; i++) {
                    prompt[i].textContent = 'Tap to Place Hologram'
                    prompt[i].style.display = 'block'
                }
                const scene = this.el.sceneEl
                scene.addEventListener('click', () => {
                    this.el.setAttribute('visible', true)

                    // Commands can be sent directly to the hologram component to control playback:
                    // 'play()'
                    // 'pause()'
                    this.el.components.hologram.play()

                    for (let i = 0; i < prompt.length; i++) {
                        prompt[i].style.display = 'none'
                    }
                    muteBtn.style.display = 'block'

                    this.el.addEventListener('onbufferempty', (evt) => {
                        for (let i = 0; i < prompt.length; i++) {
                            prompt[i].textContent = 'Buffering...'
                            prompt[i].style.display = 'block'
                        }
                    });
                    this.el.addEventListener('onbufferloaded', (evt) => {
                        for (let i = 0; i < prompt.length; i++) {
                            prompt[i].style.display = 'none'
                        }
                    });
                }, { once: true })
            }, { once: true })

            // mute/unmute functionality
            muteBtn.onclick = (evt) => {
                if (evt.bubbles) evt.stopPropagation()
                // The current mute state can be queried via the 'muted' getter, again following the API
                // of the HTMLVideoElement
                const holo = this.el.components.hologram
                holo.muted = !holo.muted
                if (holo.muted) {
                    muteBtn.classList.remove('volume-unmuted');
                    muteBtn.classList.add('volume-muted');
                }
                else {
                    muteBtn.classList.add('volume-unmuted');
                    muteBtn.classList.remove('volume-muted');
                }
            }
            muteBtn.onmousedown = (evt) => {
                if (evt.bubbles) evt.stopPropagation()
            }
        },
        tick() {},
    });
}
