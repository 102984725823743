const helpers = require('../helpers');
const VolcapDashActor = require('./dash');
const VolcapHVRActor = require('./hvr');

class VolcapActor extends HTMLElement {
    constructor() {
        super();

        this._src = null;

        this._asset = null;
        this._onInitialised = null;
        this._onUpdate = null;

        this._audioSrc = null;

        this._arEnabled = false;
    }
    connectedCallback() {
        if(this.hasAttribute('src')) {
            this._src = this.getAttribute('src');
        }
        if(this.hasAttribute('oninit')) {
            this._onInitialised = this.getAttribute('oninit');
        }
        if(this.hasAttribute('onupdate')) {
            this._onUpdate = this.getAttribute('onupdate');
        }
        if(this.hasAttribute('audio')) {
            this._audioSrc = this.getAttribute('audio');
        }
    }

    disconnectedCallback() {
        this._impl.destroy(this._scene);
        this._impl = null;
    }

    get src() { return this._src; }
    set src(newSrc) { this._src = newSrc; }

    // Public API
    play() {
        this._impl.play();
    }

    pause() {
        this._impl.pause();
    }

    seek (time) {
        this._impl.seek(time);
    }

    set muted(muted) {
        this._impl.muted = muted;
    }

    get muted() {
        return this._impl.muted;
    }

    get currentTime() {
        return this._impl.currentTime;
    }

    set currentTime(time) {
        this._impl.currentTime = time;
    }

    get duration() {
        return this._impl.duration;
    }

    set loop(loop) {
        this._impl.loop = loop;
    }

    get paused() {
        return this._impl.paused;
    }

    get ready() {
        if (this._impl)
            return this._impl.ready;
        else
            return false;
    }

    initialise(scene, renderer, opts = {}) {
        this._scene = scene;
        if(!this._src) {
            console.warn('No "src" specified for eighti actor');
            return;
        }

        // Load the format-specific player infrastructure
        if (this._src.indexOf('mpd') !== -1) {
            this._impl = new VolcapDashActor(this._src, renderer, scene, opts);
            this._arEnabled = true;
        } else {
            this._impl = new VolcapHVRActor(this._src, this._audioSrc, renderer, scene, opts);
        }

        this._impl.onended = (evt) => {
            if (this.onended) {
                this.onended(evt);
            }
        }
        this._impl.onplay = (evt) => {
            if (this.onplay) {
                this.onplay(evt);
            }
        }
        this._impl.onpause = (evt) => {
            if (this.onpause) {
                this.onpause(evt);
            }
        }
        this._impl.onloadeddata = (evt) => {
            if (this.onloadeddata) {
                this.onloadeddata(evt);
            }
        }
    }

    destroy () {
        this._impl.destroy(this._scene);
        this._impl = null;
    }

    get arEnabled() {
        return this._arEnabled;
    }

    render(width, height, camera, position, hidden) {
        if ('render' in this._impl)
            this._impl.render(width, height, camera, position, hidden);
    }

    update(elapsed, currentTime, duration) {
        this._impl.update(elapsed, currentTime, duration);

        this._callOnInit();
        if(this._onUpdate === null) {
            return;
        } else if(typeof this._onUpdate == 'function') {
            this._onUpdate(this, currentTime, duration);
        } else if(this._onUpdate in window) {
            const func = window[this._onUpdate];
            if(typeof func === 'function') {
                this._onUpdate = func;
                this._onUpdate(this, currentTime, duration);
            } else {
                console.error('"onupdate" is not a function')
                this._onUpdate = null;
            }
        }
    }

    _callOnInit() {
        if(this._onInitialised && this._onInitialise in window) {
            const func = window[this._onInitialised];
            if(typeof func === 'function') {
                func(this);
            } else {
                console.error('"oninit" is not a function');
            }
            this._onInitialise = null;
        }
    }
}

module.exports = VolcapActor;