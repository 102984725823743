class DRACOLoader extends THREE.Loader {
    constructor( manager ) {
        super( manager );
        this.decoderPath = '';
        this.decoderConfig = {};
        this.decoderBinary = null;
        this.decoderPending = null;
        this.workerLimit = 1;
        this.workerPool = [];
        this.workerNextTaskID = 1;
        this.workerSourceURL = '';
        this.defaultAttributeIDs = {
            position: 'POSITION',
            normal: 'NORMAL',
            color: 'COLOR',
            uv: 'TEX_COORD'
        };
        this.defaultAttributeTypes = {
            position: 'Float32Array',
            normal: 'Float32Array',
            color: 'Float32Array',
            uv: 'Float32Array'
        };
    }
    setDecoderPath( path ) {
        this.decoderPath = path;
        return this;
    }
    setDecoderConfig( config ) {
        this.decoderConfig = config;
        return this;
    }
    setWorkerLimit( workerLimit ) {
        this.workerLimit = workerLimit;
        return this;
    }

    setVerbosity () {

        console.warn( 'DRACOLoader: The .setVerbosity() method has been removed.' );

    }
    load( url, onLoad, onProgress, onError ) {
        var loader = new THREE.FileLoader( this.manager );
        loader.setPath( this.path );
        loader.setResponseType( 'arraybuffer' );
        if ( this.crossOrigin === 'use-credentials' ) {
            loader.setWithCredentials( true );
        }
        loader.load( url, ( buffer ) => {
            var taskConfig = {
                attributeIDs: this.defaultAttributeIDs,
                attributeTypes: this.defaultAttributeTypes,
                useUniqueIDs: false
            };
            this.decodeGeometry( buffer, taskConfig )
                .then( onLoad )
                .catch( onError );
        }, onProgress, onError );
    }

    decodeGeometry( buffer, taskConfig ) {
        // TODO: For backward-compatibility, support 'attributeTypes' objects containing
        // references (rather than names) to typed array constructors. These must be
        // serialized before sending them to the worker.
        for ( var attribute in taskConfig.attributeTypes ) {
            var type = taskConfig.attributeTypes[ attribute ];
            if ( type.BYTES_PER_ELEMENT !== undefined ) {
                taskConfig.attributeTypes[ attribute ] = type.name;
            }
        }
        //
        var taskKey = JSON.stringify( taskConfig );
        var worker;
        var taskID = this.workerNextTaskID ++;
        var taskCost = buffer.byteLength;
        // Obtain a worker and assign a task, and construct a geometry instance
        // when the task completes.
        var geometryPending = this._getWorker( taskID, taskCost )
            .then( ( _worker ) => {
                worker = _worker;
                return new Promise( ( resolve, reject ) => {
                    worker._callbacks[ taskID ] = { resolve, reject };
                    worker.postMessage( { type: 'decode', id: taskID, taskConfig, buffer }, [ buffer ] );
                    // this.debug();
                } );
            } )
            .then( ( message ) => this._createGeometry( message.geometry ) );
        // Remove task from the task list.
        geometryPending
            .finally( () => {
                if ( worker && taskID ) {
                    this._releaseTask( worker, taskID );
                    // this.debug();
                }
            } );
        return geometryPending;
    }

    _createGeometry ( geometryData ) {
        var geometry = new THREE.BufferGeometry();
        if ( geometryData.index ) {
            geometry.setIndex( new THREE.BufferAttribute( geometryData.index.array, 1 ) );
        }
        for ( var i = 0; i < geometryData.attributes.length; i ++ ) {
            var attribute = geometryData.attributes[ i ];
            var name = attribute.name;
            var array = attribute.array;
            var itemSize = attribute.itemSize;
            geometry.setAttribute( name, new THREE.BufferAttribute( array, itemSize ) );
        }
        geometry.metadata = geometryData.metadata;
        return geometry;
    }

    preload() {
        this._initDecoder();
        return this;
    }

    _loadLibrary( url, responseType ) {

        var loader = new THREE.FileLoader( this.manager );
        loader.setPath( this.decoderPath );
        loader.setResponseType( responseType );

        return new Promise( ( resolve, reject ) => {

            loader.load( url, resolve, undefined, reject );

        } );

    }


    _initDecoder () {

        if ( this.decoderPending ) return this.decoderPending;

        var useJS = typeof WebAssembly !== 'object' || this.decoderConfig.type === 'js';
        var librariesPending = [];

        if ( useJS ) {

            librariesPending.push( this._loadLibrary( 'draco_decoder.js', 'text' ) );

        } else {

            librariesPending.push( this._loadLibrary( 'draco_wasm_wrapper.js', 'text' ) );
            librariesPending.push( this._loadLibrary( 'draco_decoder.wasm', 'arraybuffer' ) );

        }

        this.decoderPending = Promise.all( librariesPending )
            .then( ( libraries ) => {

                var jsContent = libraries[ 0 ];

                if ( ! useJS ) {

                    this.decoderConfig.wasmBinary = libraries[ 1 ];

                }

                var fn = DRACOWorker.toString();

                var body = [
                    '/* draco decoder */',
                    jsContent,
                    '',
                    '/* worker */',
                    fn.substring( fn.indexOf( '{' ) + 1, fn.lastIndexOf( '}' ) )
                ].join( '\n' );

                this.workerSourceURL = URL.createObjectURL( new Blob( [ body ] ) );

            } );

        return this.decoderPending;

    }

    decodeDracoFile ( buffer, callback, attributeIDs, attributeTypes ) {
        var taskConfig = {
            attributeIDs: attributeIDs || this.defaultAttributeIDs,
            attributeTypes: attributeTypes || this.defaultAttributeTypes,
            useUniqueIDs: !! attributeIDs
        };
        this.decodeGeometry( buffer, taskConfig ).then( callback );
    }


    _getWorker ( taskID, taskCost ) {

        return this._initDecoder().then( () => {

            if ( this.workerPool.length < this.workerLimit ) {

                var worker = new Worker( this.workerSourceURL );

                worker._callbacks = {};
                worker._taskCosts = {};
                worker._taskLoad = 0;

                worker.postMessage( { type: 'init', decoderConfig: this.decoderConfig } );

                worker.onmessage = function ( e ) {

                    var message = e.data;

                    switch ( message.type ) {

                        case 'decode':
                            worker._callbacks[ message.id ].resolve( message );
                            break;

                        case 'error':
                            worker._callbacks[ message.id ].reject( message );
                            break;

                        default:
                            console.error( 'DRACOLoader: Unexpected message, "' + message.type + '"' );

                    }

                };

                this.workerPool.push( worker );

            } else {

                this.workerPool.sort( function ( a, b ) {

                    return a._taskLoad > b._taskLoad ? - 1 : 1;

                } );

            }

            var worker = this.workerPool[ this.workerPool.length - 1 ];
            worker._taskCosts[ taskID ] = taskCost;
            worker._taskLoad += taskCost;
            return worker;

        } );

    }

    _releaseTask ( worker, taskID ) {
        worker._taskLoad -= worker._taskCosts[ taskID ];
        delete worker._callbacks[ taskID ];
        delete worker._taskCosts[ taskID ];
    }
    debug () {

        console.log( 'Task load: ', this.workerPool.map( ( worker ) => worker._taskLoad ) );

    }
    dispose() {
        for ( var i = 0; i < this.workerPool.length; ++ i ) {
            this.workerPool[ i ].terminate();
        }
        this.workerPool.length = 0;
        return this;
    }

}




/* WEB WORKER */

function DRACOWorker() {

    var decoderConfig;
    var decoderPending;

    onmessage = function ( e ) {

        var message = e.data;

        switch ( message.type ) {

            case 'init':
                decoderConfig = message.decoderConfig;
                decoderPending = new Promise( function ( resolve/*, reject*/ ) {

                    decoderConfig.onModuleLoaded = function ( draco ) {

                        // Module is Promise-like. Wrap before resolving to avoid loop.
                        resolve( { draco: draco } );

                    };

                    DracoDecoderModule( decoderConfig );

                } );
                break;

            case 'decode':
                var buffer = message.buffer;
                var taskConfig = message.taskConfig;
                decoderPending.then( ( module ) => {

                    var draco = module.draco;
                    var decoder = new draco.Decoder();
                    var decoderBuffer = new draco.DecoderBuffer();
                    decoderBuffer.Init( new Int8Array( buffer ), buffer.byteLength );

                    try {

                        var geometry = decodeGeometry( draco, decoder, decoderBuffer, taskConfig );

                        var buffers = geometry.attributes.map( ( attr ) => attr.array.buffer );

                        Object.values(geometry.metadata).forEach( (meta) => { if (meta && meta.buffer) buffers.push(meta.buffer) })

                        if ( geometry.index ) buffers.push( geometry.index.array.buffer );

                        self.postMessage( { type: 'decode', id: message.id, geometry }, buffers );

                    } catch ( error ) {

                        console.error( error );

                        self.postMessage( { type: 'error', id: message.id, error: error.message } );

                    } finally {

                        draco.destroy( decoderBuffer );
                        draco.destroy( decoder );

                    }

                } );
                break;
        }
    };

    function decodeGeometry( draco, decoder, decoderBuffer, taskConfig ) {

        var attributeIDs = taskConfig.attributeIDs;
        var attributeTypes = taskConfig.attributeTypes;
        var metadataFields = taskConfig.metadataFields;

        var dracoGeometry;
        var decodingStatus;

        var geometryType = decoder.GetEncodedGeometryType( decoderBuffer );

        if ( geometryType === draco.TRIANGULAR_MESH ) {

            dracoGeometry = new draco.Mesh();
            decodingStatus = decoder.DecodeBufferToMesh( decoderBuffer, dracoGeometry );

        } else if ( geometryType === draco.POINT_CLOUD ) {

            dracoGeometry = new draco.PointCloud();
            decodingStatus = decoder.DecodeBufferToPointCloud( decoderBuffer, dracoGeometry );

        } else {

            throw new Error( 'DRACOLoader: Unexpected geometry type.' );

        }

        if ( ! decodingStatus.ok() || dracoGeometry.ptr === 0 ) {

            throw new Error( 'DRACOLoader: Decoding failed: ' + decodingStatus.error_msg() );

        }

        var geometry = { index: null, attributes: [], metadata: {} };

        // Gather all vertex attributes.
        for ( var attributeName in attributeIDs ) {

            var attributeType = self[ attributeTypes[ attributeName ] ];

            var attribute;
            var attributeID;

            // A Draco file may be created with default vertex attributes, whose attribute IDs
            // are mapped 1:1 from their semantic name (POSITION, NORMAL, ...). Alternatively,
            // a Draco file may contain a custom set of attributes, identified by known unique
            // IDs. glTF files always do the latter, and `.drc` files typically do the former.
            if ( taskConfig.useUniqueIDs ) {

                attributeID = attributeIDs[ attributeName ];
                attribute = decoder.GetAttributeByUniqueId( dracoGeometry, attributeID );

            } else {

                attributeID = decoder.GetAttributeId( dracoGeometry, draco[ attributeIDs[ attributeName ] ] );

                if ( attributeID === - 1 ) continue;

                attribute = decoder.GetAttribute( dracoGeometry, attributeID );

            }

            geometry.attributes.push( decodeAttribute( draco, decoder, dracoGeometry, attributeName, attributeType, attribute ) );

        }

        // Extract frame metadata
        const metadata = decoder.GetMetadata( dracoGeometry );
        const mc = new draco.MetadataQuerier();
        for ( let name in metadataFields ) {
            if (mc.HasEntry(metadata, name)) {
                const fieldType = metadataFields[name];
                let value;
                if (fieldType === 'IntEntryArray') {
                    let out_values = new draco.DracoInt32Array();
                    mc[`Get${fieldType}`](metadata, name, out_values);
                    const size = out_values.size();
                    value = new Int32Array( size );
                    for (let i = 0; i < size; i++) {
                        value[i] = out_values.GetValue(i);
                    }
                    draco._free( out_values );
                } else if (fieldType === 'EntryDoubleArray') {
                    let out_values = new draco.DracoDoubleArray();
                    mc[`Get${fieldType}`](metadata, name, out_values);
                    const size = out_values.size();
                    value = new Float64Array( size );
                    for (let i = 0; i < size; i++) {
                        value[i] = out_values.GetValue(i);
                    }
                    draco._free( out_values );
                } else {
                    value = mc[`Get${fieldType}`](metadata, name);
                }
                geometry.metadata[name] = value;
            }
        }

        // Extract delta attributes
        for (let deltaId of geometry.metadata['deltaIds'] || []) {
            attribute = decoder.GetAttributeByUniqueId( dracoGeometry, deltaId );
            geometry.attributes.push( decodeAttribute( draco, decoder, dracoGeometry, deltaId, Float32Array, attribute ) );
        }

        // Extract skeleton stuff
        const skeletonAttributes = {
            'weightsAtt': Float32Array,
            'bindingsAtt': Int32Array,
        };
        for (const [key, value] of Object.entries(skeletonAttributes)) {
            if(geometry.metadata[key]) {
                let attribute = decoder.GetAttributeByUniqueId( dracoGeometry, geometry.metadata[key]);
                geometry.attributes.push( decodeAttribute( draco, decoder, dracoGeometry, key, value, attribute ) );
            }
        }

        // Add index.
        if ( geometryType === draco.TRIANGULAR_MESH ) {

            // Generate mesh faces.
            var numFaces = dracoGeometry.num_faces();
            var numIndices = numFaces * 3;
            var dataSize = numIndices * 4;
            var ptr = draco._malloc( dataSize );
            decoder.GetTrianglesUInt32Array( dracoGeometry, dataSize, ptr );
            var index = new Uint32Array( draco.HEAPU32.buffer, ptr, numIndices ).slice();
            draco._free( ptr );

            geometry.index = { array: index, itemSize: 1 };

        }

        draco.destroy( dracoGeometry );

        return geometry;

    }

    function decodeAttribute( draco, decoder, dracoGeometry, attributeName, attributeType, attribute ) {

        var numComponents = attribute.num_components();
        var numPoints = dracoGeometry.num_points();
        var numValues = numPoints * numComponents;
        var dracoArray;
        var ptr;
        var array;

        switch ( attributeType ) {

            case Float32Array:
                var dataSize = numValues * 4;
                ptr = draco._malloc( dataSize );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_FLOAT32, dataSize, ptr );
                array = new Float32Array( draco.HEAPF32.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            case Int8Array:
                ptr = draco._malloc( numValues );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_INT8, numValues, ptr );
                geometryBuffer[ attributeName ] = new Int8Array( draco.HEAP8.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            case Int16Array:
                var dataSize = numValues * 2;
                ptr = draco._malloc( dataSize );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_INT16, dataSize, ptr );
                array = new Int16Array( draco.HEAP16.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            case Int32Array:
                var dataSize = numValues * 4;
                ptr = draco._malloc( dataSize );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_INT32, dataSize, ptr );
                array = new Int32Array( draco.HEAP32.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            case Uint8Array:
                ptr = draco._malloc( numValues );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_UINT8, numValues, ptr );
                geometryBuffer[ attributeName ] = new Uint8Array( draco.HEAPU8.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            case Uint16Array:
                var dataSize = numValues * 2;
                ptr = draco._malloc( dataSize );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_UINT16, dataSize, ptr );
                array = new Uint16Array( draco.HEAPU16.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            case Uint32Array:
                var dataSize = numValues * 4;
                ptr = draco._malloc( dataSize );
                decoder.GetAttributeDataArrayForAllPoints( dracoGeometry, attribute, draco.DT_UINT32, dataSize, ptr );
                array = new Uint32Array( draco.HEAPU32.buffer, ptr, numValues ).slice();
                draco._free( ptr );
                break;

            default:
                throw new Error( 'DRACOLoader: Unexpected attribute type.' );
        }
        return {
            name: attributeName,
            array: array,
            itemSize: numComponents
        };
    }
};

/** Deprecated static methods */

/** @deprecated */
DRACOLoader.setDecoderPath = function () {

    console.warn( 'DRACOLoader: The .setDecoderPath() method has been removed. Use instance methods.' );

};

/** @deprecated */
DRACOLoader.setDecoderConfig = function () {
    console.warn( 'DRACOLoader: The .setDecoderConfig() method has been removed. Use instance methods.' );
};

/** @deprecated */
DRACOLoader.releaseDecoderModule = function () {
    console.warn( 'DRACOLoader: The .releaseDecoderModule() method has been removed. Use instance methods.' );
};

/** @deprecated */
DRACOLoader.getDecoderModule = function () {
    console.warn( 'DRACOLoader: The .getDecoderModule() method has been removed. Use instance methods.' );
};

module.exports = DRACOLoader;


