const helpers = require('../helpers');
const VolcapAudio = require("../audio");

class VolcapHVRActor {
    constructor(src, audioSrc, renderer, scene, opts = {}) {
        let {autoplay = false, muted = true, loop = false} = opts;
        this._src = src;
        // Ideally we wouldn't be keeping references to these from here
        this._renderer = renderer;

        this._actor = null;
        this._asset = null;
        this._renderMethod = null;
        this._onInitialised = null;
        this._onUpdate = null;
        this._loop = loop;
        this._scale = 1;

        this._isInitialised = false;
        this._playbackStarted = false;
        this._autoplay = autoplay;

        this._audioSrc = audioSrc;

        this._audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this._audioGainNode = this._audioContext.createGain();
        this._audioGainNode.gain.value = muted ? 0 : 1;
        this._audioGainNode.connect(this._audioContext.destination);

        // play nothing so we have usable audio content
        let fakeBuffer = this._audioContext.createBuffer(1, 1, 44100);
        let source = this._audioContext.createBufferSource();
        source.buffer = fakeBuffer;
        source.connect(this._audioGainNode);
        source.start();

        if(!this._src) {
            console.warn('No "src" specified for eighti actor');
            return;
        }

        // Load the format-specific player infrastructure
        let playerScript = document.createElement('script');
        playerScript.src = helpers.interfacePath + '/eighti.min.js';
        playerScript.onload = this._onVolcapLoaded.bind(this);
        document.body.appendChild(playerScript);
    }

    set muted(muted) {
        this._audioGainNode.gain.value = muted ? 0 : 1;
        if  (this._audioContext.state == "suspended") {
            this._audioContext.resume();
            // We need to seek here or the audio playback will start at zero
            if (this._audio)
                this._audio.seek(this.currentTime);
        }
    }

    get muted() {
        return this._audioGainNode.gain.value === 0 || this._audioContext.state === "suspended";
    }

    // Public API
    play() {
        this._asset.play();
        if (this._audio) {
            this._audio.play();
        }
    }

    pause() {
        this._asset.pause();
        if (this._audio) {
            this._audio.pause();
        }
    }

    seek (time) {
        this._asset.seek(time);
        if(this._audio) {
            this._audio.seek(time);
        }
    }

    get currentTime() {
        return this._asset.getCurrentTime();
    }

    get duration() {
        return this._asset.getDuration();
    }

    isCaching() {
        // HACK : currently buffer is hard coded to 2 seconds and caching state is not being set
        // so add temp check here, should be using assetState.isCaching()
        const duration = this._asset.getDuration();
        let bufferFillRatio = this._asset.getBufferFillRatio();
        let isCaching = bufferFillRatio < 0.25 && duration > 2.0;
        // END HACK
        return isCaching;
    }

    isInitialising() {
        return this._asset.getState().isInitialising();
    }

    isPlaying() {
        return this._asset.getState().isPlaying();
    }

    get paused() {
        return !this._asset.getState().isPlaying()
    }

    get ready() {
        return Boolean(this._asset);
    }

    getBounds() {
        return this._asset.getBounds();
    }

    setLooping(loop) {
        this._loop = loop;
    }

    _onVolcapLoaded() {
        let logFunc = null;
        if(this._onLog && this._onLog in window) {
            const func = window[this._onLog];
            if(typeof func === 'function') {
                logFunc = func;
            } else {
                console.error('"onlog" set but is not a function');
            }
        }
        EightI.Env.initialise("eighti-web-player", "0.1", this._onEightiInitialise.bind(this), logFunc);

        const wasmSupported = (() => {
            try {
                if (typeof WebAssembly === "object"
                    && typeof WebAssembly.instantiate === "function") {
                    const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
                    if (module instanceof WebAssembly.Module)
                        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
                }
            } catch (e) {
            }
            return false;
        })();

        if(wasmSupported) {
            EightI.Env.registerFileURL("libeighti.wasm", helpers.interfacePath + "/libeighti.wasm");
            EightI.Env.registerFileURL("libeighti.wast", helpers.interfacePath + "/libeighti.wast");
            EightI.Env.registerFileURL("libeighti.temp.asm.js", helpers.interfacePath + "/libeighti.temp.asm.js");

            var script = document.createElement('script');
            script.src = helpers.interfacePath + "/libeighti.js";
            document.body.appendChild(script);
        }
        else {
            console.log('Browser does not support Web Assembly!')
            document.addEventListener("DOMContentLoaded", function() {
                helpers.displayLoadingError()
            });
        }
    }

    destroy() {
        this._audioContext.close();
    }

    _onEightiInitialise() {
        window.THREE = THREE;
        this._player = new EightI.Player(this._renderer.getContext());
        this._viewport = new EightI.Viewport();

        this._asset = new EightI.Asset(this._src, 2.0);
        this._asset.onInitialised = function(errorCode) {
            this._callOnInit();
        }.bind(this);
        this._asset.onSelectRepresentation = function(adaptationSet, representationIndex, representations) {
            // can chack what codecs / formats / data rates we want to support for playback
            if(adaptationSet.codec == 'HVR53' || adaptationSet.codec == 'SVTHVR53' || adaptationSet.codec == 'SVTHVR55') {
                if(representations.length == 1) {
                    if(representations[representationIndex].maxVoxelCount > 1000000) {
                        console.warn("Attempting to load EightI asset that may negatively impact playback performance");
                    }
                    return true;
                }
                // Limit to 30fps / 600k voxels
                if(representations[representationIndex].maxFPS <= 15.0 && representations[representationIndex].maxVoxelCount <= 600000) {
                    return true;
                }
            } else if (adaptationSet.codec == 'pcm' || adaptationSet.codec == 'wav') {
                if(!this._audio) {
                    this._audio = new VolcapAudio(this._audioContext, this._audioGainNode);
                }
                return true;
            }

            return false;
        }.bind(this);
        this._asset.onRepresentationDataRecieved = function(mimeType, codec, startTime, buffer) {
            if(codec == "ogg" || codec == "pcm" || codec == "wav") {
                if(this._audio) {
                    this._audio.add(startTime, buffer);
                } else {
                    console.error("Audio representation data not expected");
                }
            }
        }.bind(this);   
        this._actor = new EightI.Actor();
        if(userAgentIs.mobile()) {
            this._renderMethod = new EightI.RenderMethod("PointSprite");
            // PointSprite render mode behaves strangely on iOS devices where the
            // pixel ratio is not 1. We can scale the viewport to account for this.
            this._scale = this._renderer.getPixelRatio();
        } else {
            this._renderMethod = new EightI.RenderMethod("FastCubes");
        }
        this._asset.create();
        this._actor.setAsset(this._asset);
        this._actor.setRenderMethod(this._renderMethod);
        let matrix = new THREE.Matrix4();
        matrix.makeScale(0.01, 0.01, 0.01); // A meters to centimeters conversion is required
        this._actor.setTransform(matrix);

        if(this._audioSrc) {
            this._audio = new VolcapAudio(this._audioContext, this._audioGainNode);
            this._audio.add(0, this._audioSrc);
        }
    }

    render(width, height, camera) {
        // Configure the viewport using the THREE.js camera
        if (this._viewport) {
            this._viewport.setDimensions(0, 0, width * this._scale, height * this._scale);
            this._viewport.setViewMatrix(camera.matrixWorldInverse);
            this._viewport.setProjMatrix(camera.projectionMatrix);
        }

        // Mark the actor to be rendered into the viewport
        this._player.willRender(this._actor, this._viewport);

        // Allow player to get ready for rendering
        this._player.prepareRender();
        // Render the actor into the viewport
        this._player.render(this._actor, this._viewport);
    }

    update(elapsed, currentTime, duration) {

        let isCaching = this.isCaching();

        // Wait until the asset has been initialised and isnt caching before triggering playback
        if(!this.isInitialising() && !this.isPlaying() && !this._playbackStarted && !isCaching && this._isPlaying) {
            this.play();
            this._playbackStarted = true;
        }
        // If the asset is caching pause playback
        else if((isCaching && this.isPlaying()) || (!this.isPlaying() && this._playbackStarted) ) {
            this.pause();
            this._playbackStarted = false;
        } else if (!this.isInitialising() && !this.isPlaying() && !this._playbackStarted && !isCaching && !this._isInitialised) {
            this._isInitialised = true;
            // this._stopLoadingAnimation();
            if (this.onloadeddata) {
                this.onloadeddata();
            }
            if(this._autoplay) {
                this.play();
            }
        }

        // Allow EightI library to update
        EightI.Env.update();

        this._asset.update(elapsed);

        if(this._audio) {
            this._audio.update(currentTime, duration);
        }

        if (duration > 0 && currentTime >= duration) {
            if (this._loop) {
                this.seek(0);
                this.play();
            } else {
                this.pause();
            }
        }

        this._callOnInit();
        if(this._onUpdate === null) {
            return;
        } else if(typeof this._onUpdate == 'function') {
            this._onUpdate(this, currentTime, duration);
        } else if(this._onUpdate in window) {
            const func = window[this._onUpdate];
            if(typeof func === 'function') {
                this._onUpdate = func;
                this._onUpdate(this, currentTime, duration);
            } else {
                console.error('"onupdate" is not a function')
                this._onUpdate = null;
            }
        }
    }

    _callOnInit() {
        if(this._onInitialised && this._onInitialise in window) {
            const func = window[this._onInitialised];
            if(typeof func === 'function') {
                func(this);
            } else {
                console.error('"oninit" is not a function');
            }
            this._onInitialise = null;
        }
    }
}

module.exports = VolcapHVRActor;
